import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  var _component_BaseTableList = _resolveComponent("BaseTableList");

  return _openBlock(), _createBlock(_component_BaseTableList, _mergeProps(_ctx.$attrs, {
    class: "card-table-list"
  }), _createSlots({
    header: _withCtx(function (slotProp) {
      return [_renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps(slotProp)))];
    }),
    _: 2
  }, [_renderList(_ctx.$attrs.cols, function (col, idx) {
    return {
      name: col.slot,
      fn: _withCtx(function (slotProp) {
        return [_renderSlot(_ctx.$slots, col.slot, _normalizeProps(_guardReactiveProps(slotProp)))];
      })
    };
  }), _ctx.$slots['content-end'] ? {
    name: "content-end",
    fn: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "content-end")];
    }),
    key: "0"
  } : undefined]), 1040);
}